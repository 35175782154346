<template>
  <div v-if="errmsg !== ''" id="app">
    <mobile-header></mobile-header>
    <div class="err-box">
      <i class="err-icon"></i>
      <span class="err-msg">{{errmsg}}</span>
    </div>
  </div>
  <div v-else id="app">
    <!-- <mobile-header></mobile-header> -->
    <transition name="page">
      <router-view/>
      <!-- 打开时页面提示 -->
      <div class="cover-box">
        <i class="waiting-icon"></i>
        <span class="waiting-text">......</span>
      </div>
      <!-- 打开时页面提示 -->
    </transition>
  </div>
</template>
<script>
// import Loading from '@/components/Loading';
import MobileHeader from "@/components/MobileHeader";
import { getUrlKey } from "@/lib/util";
import { mapState } from "vuex";
import Fetch from "@/lib/fetch";
export default {
  // name: 'app',
  components: {
    MobileHeader
    // Loading
  },
  data: function() {
    return {
      errmsg: ""
    };
  },
  async created() {
    try {
      const wechat_appid = await this.$getProfileValue("WECHAT_APP_ID");
      const alipay_appid = await this.$getProfileValue("ALIPAY_APP_ID");
      const forward_base =
        (await this.$getProfileValue("PHONE-HOST_BASE")) + "/#/pay";
      // const forward_base = 'https://weixin.tuoxi-tech.com'+'/#/pay';
      const quotationCode = getUrlKey("quotationCode");
      // alert(`Index: ${quotationCode}`);
      // const quotation_info = await this.$getQuotationInfo(
      //   getUrlKey("quotationCode")
      // );
      const quotation_info = await this.$getQuotationInfo(quotationCode);
      if (!quotation_info.data) {
        alert(quotation_info.errmsg);
      }
      localStorage.setItem(
        "quotation_code",
        quotation_info.data.quotation_code
      );
      localStorage.setItem("equipment_id", quotation_info.data.equipment_id);
      localStorage.setItem("device_id", quotation_info.data.device_id);
      localStorage.setItem("item_id", quotation_info.data.item_id);
      let ua = window.navigator.userAgent.toLowerCase();
      let time_stamp = Date.parse(new Date());
      let client = "";
      let redirect_uri = "";
      let forward_url = "";

      // 微信
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        client = "wechat";
        redirect_uri = encodeURIComponent(forward_base + "?client=" + client);
        // console.log('redirect_uri: '+redirect_uri);
        forward_url =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          wechat_appid +
          "&redirect_uri=" +
          redirect_uri +
          "&response_type=code&scope=snsapi_userinfo&state=" +
          time_stamp +
          "#wechat_redirect";
        // console.log('forward_url: '+forward_url);
        location.href = forward_url;
      } else if (ua.match(/AlipayClient/i) == "alipayclient") {
        // 支付宝
        client = "alipay";
        redirect_uri = encodeURIComponent(forward_base + "?client=" + client);
        // console.log('redirect_uri: '+redirect_uri);
        forward_url =
          "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=" +
          alipay_appid +
          "&scope=auth_user&redirect_uri=" +
          redirect_uri;
        // console.log('forward_url: '+forward_url);
        location.href = forward_url;
      } else {
        // 都不是
        client = "Doesn't support this Brower. Brower UA: " + ua;
        this.errmsg = "请使用微信或支付宝客户端扫码使用！";
        throw this.errmsg;
      }
      if (client != null) {
        localStorage.setItem("client", client);
      }
    } catch (error) {
      // console.log(error);
    }
  },
  computed: {},
  methods: {}
};
</script>
<style lang="scss">
@import "@/base.scss";

#app {
  .err-box {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    overflow: hidden;
    text-align: center;
    .err-icon {
      display: inline-block;
      margin-top: 50px;
      height: 130px;
      width: 130px;
      background-image: url(~@/assets/mobile/error.png);
      background-size: 105%;
      background-repeat: no-repeat;
    }
    .err-msg {
      display: block;
      margin-top: 20px;
      padding: 0 20px;
      font-size: 18px;
      white-space: normal;
    }
  }
  // 打开时页面提示
  .cover-box {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    overflow: hidden;
    text-align: center;
    .waiting-icon {
      display: inline-block;
      margin-top: 50px;
      height: 130px;
      width: 130px;
      background-image: url(~@/assets/mobile/loading.gif);
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .waiting-text {
      display: block;
      line-height: 2;
    }
  }
  // 打开时页面提示
}
</style>
